import Vue from 'vue'
export function regMatch(str, msg) {
    if (!str) {
        Vue.prototype.$message({
            message: msg + "不能为空",
            type: "error",
            time: 2000,
            hasClose: true,
        });
        return false;
    } else {
        return true
    }
}